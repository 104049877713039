import "../styles/app.scss";
import "@fortawesome/fontawesome-pro/css/all.css";
import "toastify-js/src/toastify.css";

import "@hotwired/turbo";
import { StreamActions } from "@hotwired/turbo";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import Reveal from 'stimulus-reveal-controller';
import { Alert } from "tailwindcss-stimulus-components";
import toast_stream from '../streams/toast_stream';
import url_push_stream from '../streams/url_push_stream';

window.Stimulus = Application.start();
window.Stimulus.register("reveal", Reveal);
const context = require.context("../controllers", true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));
window.Stimulus.register('alert', Alert); 

StreamActions.toast = toast_stream;
StreamActions.urlPush = url_push_stream;