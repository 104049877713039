import {Modal} from "tailwindcss-stimulus-components";

export default class extends Modal {
    static targets = [
        ...Modal.targets,
        ...['modalContent', 'image', 'link', 'frame']
    ];
    static values = {
        ...Modal.values,
        ...{ url: String }
    };

    open(e) {
        debugger;
        this.imageTarget.setAttribute('src',  e.currentTarget.src);
        this.linkTarget.setAttribute('href',  e.currentTarget.src);
        this.frameTarget.setAttribute('src',  e.currentTarget.dataset.favurl);
        super.open(e);
    }

    close(e) {
        super.close(e); 
    }
}