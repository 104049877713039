import { Controller } from "@hotwired/stimulus";
import Cookies from 'js-cookie';

const csrftoken = Cookies.get('csrftoken');

export default class extends Controller {
  static values = {
    url: { type: String, default: '' },
    lock: { type: String, default: 'False' },
  };

  static targets = ['button', 'container'];

  containerTargetConnected() {
    if (this.lockValue === 'lock') {
      this.#refresh();
    }
  }

  trigger() {
    this.buttonTarget.setAttribute('disabled', true);
    fetch(this.urlValue, {
      method: 'post',
      headers: {'X-CSRFToken': csrftoken},
    }).then(() => {
      this.#refresh();
    });
  }

  #refresh() {
    var timer = setInterval(() => {
      fetch(this.urlValue)
        .then(response => {
          if (response.status === 204) {
            this.buttonTarget.removeAttribute('disabled');
            clearInterval(timer);
          } else {
            response.text().then(html => this.containerTarget.innerHTML=html);
          }
      });
    }, 30000);
  }
}