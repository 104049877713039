import Toastify from 'toastify-js';

export default function () {
  const message = this.getAttribute("message");
  const status = this.getAttribute("status");

  const statusMap = {
    success: {
      background: '#f0fdf4',
      color: '#166534'
    },
    warning: {
      background: '#fefce8',
      color: '#854d0e'
    },
    info: {
      background: '#eff6ff',
      color: '#1e40af'
    },
    danger: {
      background: '#fef2f2',
      color: '#991b1b'
    }
  };

  Toastify({
    text: message,
    style: statusMap[status],
    duration: 3000,
    gravity: "top",
    position: "center",
    stopOnFocus: true,
  }).showToast();
}
