import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    delay: { type: Number, default: 500 },
  };

  static targets = ['input'];

  inputTargetConnected(target) {
    target.setAttribute('data-action', 'input->debounce-search#change');
    var len = target.value.length;
    target.focus();
    target.setSelectionRange(len, len);
  }

  change() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.#submit();
    }, this.delayValue);
  }

  #submit() {
    this.element.requestSubmit();
  }
}